import React from 'react';
import "animate.css";
import Mobile from "./komponenty/mobile/mobile";
import Desktop from "./komponenty/desktop/desktop";
import Tablet from "./komponenty/tablet/tablet";


function App() {
  return (
    <div>
    
    <Mobile />
    <Desktop />
    <Tablet />






</div>
  );
}

export default App;
