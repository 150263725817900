import React from 'react';
import './desktop-main.css';
import "./desktop-zakazane-styly.css";
import "animate.css";
import "./multicss.css";


function Desktop() {
  return (
    <div>
  
    <img src='./img/PPL_obrázek.jpg' alt='developer hal' className='developer-hal-ppl'></img>

    {/* <img src='./img/logo.png' alt='logo' className='developer-hal-logo'></img> */}

    <a href='mailto:simpleeconomy@seznam.cz' className='simple-economy-mail-desktop animate__animated animate__backInDown animate__delay-0.5s'>simpleeconomy@seznam.cz</a>

    <div className='carka-navbar-desktop'></div>


    <nav class="navbar-desktop animate__animated animate__backInDown animate__delay-0.5s">
        <a href="#sluzby" class="nav-link-desktop">SLUŽBY</a>
        <a href='#reference' class="nav-link-desktop">REFERENCE</a>
        <a href='#kontaktni-informace' class="nav-link-desktop">KONTAKT</a>
        <a href='#kontaktni-informace' class="nav-link-desktop">KONTAKT</a>
        <a href='#kontaktni-informace' class="nav-link-desktop">KONTAKT</a>

    </nav>

    <h3 className='desktop-h3-first'>Společně budujeme vaši budoucnost a prosperitu.</h3>
    <h1 className='desktop-h1-first'>SIMPLE ECONOMY</h1>
 

    {/* <button className='desktop-button-1'>KONTAKT</button> */}

    <div className='jecnicka'>Projektové řízení</div>
    <div className='dvojka'>Služby developera</div>
    <div className='trojka'>Pozemkové úpravy</div>
    <div className='ctverka'>Due diligence</div>

    <div className='pokracovani-desktop'></div>



    <h1 id='sluzby' className='desktop-sluzby-main-text'>NAŠE SLUŽBY</h1>
    <h3 className='desktop-sluzby-second-text'>Realizace vašich vizí od A do Z</h3>

    <div className='desktop-pozadi-2'></div>

    <img src='./img/jusda.jpg' className='obrazek1'></img>
    <img src='./img/nika.png' className='obrazek2'></img>
    <img src='./img/vetro.jpg' className='obrazek1'></img>
    <img src='./img/pozemek.png' className='obrazek2'></img>
    <img src='./img/PPL_obrázek.jpg' className='obrazek1'></img>
    <img src='./img/prazdroj.PNG' className='obrazek2'></img>
    <img src='./img/fve.PNG' className='obrazek1'></img>
    <img src='./img/heraues-foto.png' className='obrazek2'></img>


{/* tablet here */}
<div className="image-container-desktop-tablet">
  <img src="./img/jusda.jpg" className="tablet-res1" />
  <p className="image-text-desktop-tablet"><text>Projekt Jusda</text>, Pardubice 2017</p>
  <p className="image-text-desktop-tablet">Hala 10 000 m², pozemek 20 000 m²</p>
</div>

<div className="image-container-desktop-tablet">
<img src='./img/nika.png' className='tablet-res1'></img>
<p className="image-text-desktop-tablet"><text>Projekt Nika Logistics</text>, Pardubice 2018</p>
<p className="image-text-desktop-tablet">Hala 10 000 m², pozemek 18 000 m²</p>
</div>

<div className="image-container-desktop-tablet">
<img src='./img/vetro.jpg' id='desktop-img-down' className='tablet-res1'></img>
<p className="image-text-desktop-tablet"><text>Projekt Vetro</text>, Hradec Králové 2019</p>
<p className="image-text-desktop-tablet">Hala 15 000 m², pozemek 30 000 m²</p>
</div>

<div className="image-container-desktop-tablet">
<img src='./img/pozemek.png' desktop-img-down className='tablet-res1'></img>
<p className="image-text-desktop-tablet"><text>Projekt Revitalizace brownfieldu</text>, Hradec Králové 2020</p>
<p className="image-text-desktop-tablet">Pozemek 50 000 m²</p>
</div>

<div className="image-container-desktop-tablet">
<img src='./img/PPL_obrázek.jpg' className='tablet-res1'></img>
<p className="image-text-desktop-tablet"><text>Projekt PPL</text>, Hradec Králové 2021</p>
<p className="image-text-desktop-tablet">Hala 10 000 m², pozemek 36 000 m²</p>
</div>

<div className="image-container-desktop-tablet">
<img src='./img/prazdroj.PNG' className='tablet-res1'></img>
<p className="image-text-desktop-tablet"><text>Projekt Prazdroj</text>, Hradec Králové 2022</p>
<p className="image-text-desktop-tablet">Hala 3 600 m², pozemek 8 900 m²</p>
</div>

<div className="image-container-desktop-tablet">
<img src='./img/fve.PNG' className='tablet-res1'></img>
<p className="image-text-desktop-tablet"><text>FVE 990 kWp</text>, Hradec Králové 2023</p>
<p className="image-text-desktop-tablet">Pozemek 15 000 m²</p>
</div>

<div className="image-container-desktop-tablet">
<img src='./img/heraues-foto.png' className='tablet-res1'></img>
<p className="image-text-desktop-tablet"><text>Projekt Heraeus</text>, Hradec Králové 2024</p>
<p className="image-text-desktop-tablet">Hala 4 890 m², pozemek 9 500 m²</p>
</div>

<div className="image-container-desktop-tablet">
<img src='./img/halad.png' className='tablet-res1'></img>
  <p className="image-text-desktop-tablet"><text>Projekt Hala D</text>, Hradec Králové 2025</p>
  <p className="image-text-desktop-tablet">Hala 10 200 m², pozemek 25 000 m² - <i>příprava pro zákazníka</i></p>
</div>





    <div className='desktop-kontakt-background'></div>


      <h1 id='kontaktni-informace' className='desktop-kontakt-main-text'>KONTAKTNÍ INFORMACE</h1>
      <h3 className='desktop-kontakt-second-text'>S radostí Vám zodpovíme veškeré informace !</h3>



<h3 className='desktop-kontakt-1'><b>Název společnosti :</b> <text className='desktop-kontakt-1-second'>SIMPLE ECONOMY s.r.o.</text></h3>

<h3 className='desktop-kontakt-2'><b>Kontaktní telefon :</b> <text className='desktop-kontakt-1-second'>simpleeconomy@seznam.cz</text></h3>

<h3 className='desktop-kontakt-2'><b>Kontaktní e-mail :</b> <text className='desktop-kontakt-1-second'>+420 724 582 843</text></h3>

<h3 className='desktop-kontakt-2'><b>Sídlo společnosti :</b> <text className='desktop-kontakt-1-second'>Zadní Luhy 405, Zlín 763 02</text></h3>

<h3 className='desktop-kontakt-2'>
  <b>IČ :</b> <text className='desktop-kontakt-1-second'>071 20 362</text>
</h3>



<h3 className='desktop-kontakt-3'>© 2023-2024 SIMPLE ECONOMY s.r.o., <br></br>
  <i className='desktop-font-weigh-lighter'>vytvořil Matyáš Hiess, </i>
    <a className='desktop-font-weigh-lighter-odkaz' href='https://social-specialist.cz/' target='blank'> (www.social-specialist.cz)</a>
</h3>


<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2606.0750917009486!2d17.604241376882346!3d49.21810297532465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47130ced88a4f037%3A0x8b4d87afd83438f9!2sZadn%C3%AD%20luhy%20405%2C%20763%2002%20Zl%C3%ADn%204-Louky!5e0!3m2!1scs!2scz!4v1731942617431!5m2!1scs!2scz"
className='desktop-mapa-footer' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>










</div>
  );
}

export default Desktop;
