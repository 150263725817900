import React from 'react';
import "animate.css";

// 1420px
import './1450.css';

// 1024px
import './1024.css';

function Tablet() {
  return (
    <div>
    



</div>
  );
}

export default Tablet;
